import React, { Component } from "react"
import { ButtonDegrade, CardElevation } from '@findep/mf-landings-core'
import MobileFriendly from '@material-ui/icons/MobileFriendly';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import ButtonDegradeFirma from '../elements/ButtonDegradefirma'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Backdrop, Snackbar,  Container } from "@material-ui/core"
import Alert from '@material-ui/lab/Alert';
import { navigate } from "../../helpers/queryNavigate"
import { withThemeProps } from '@findep/microfronts-core'
import { ProveService } from '../../services/LandingV4'
import CheckIcon from '@material-ui/icons/CheckCircle';
import { getPathById } from "../../helpers/array"
import axios from 'axios'
import { iconStyle, h1Style, root } from '../../styles/lo-sentimos'
import { VerificacionesTuboService } from '../../services/LandingV4/verificacionesTubo'

const proveSrv = new ProveService();
export class VerificacionProve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flujo: !((typeof window != 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window != 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
      loading: false,
      errorService: false,
      success: false,
      mensaje: '',
      phone: '',

      next_step: false,
      timeout: false,
      count: 60,
      secondsToWait: 0,
      correctCode: false,

      authenticationUrl: '',
      instantPath: '',
      trustScore: '',
      checkInstant: false,
      countnosms: 15,

      swNavigate: '',
      btnReLink: true,
      checkProve: false,
      phone_servicio:''
    }
    this.handleStartStop = this.handleStartStop.bind(this);
    this.handleStartStopnosms = this.handleStartStopnosms.bind(this);
    this.handleStartStop()
    this.handleStartStopnosms()
  }

  async componentDidUpdate(prevProp, prevState) {


    if (prevState.count !== this.state.count) {
      if (this.state.count === 0 && !this.state.correctCode) {
        this.setState({ timeout: true , next_step:true})
      }
    }

    if (prevState.countnosms !== this.state.countnosms) {
      if (this.state.countnosms === 0) {
        this.setState({ timeoutnosms: true })
      }
    }
  }

  handleStartStop() {
    this.timer = setInterval(() => this.setState(prevState => {
      if (prevState.count === 0) return null;

      return {
        count: prevState.count - 1,
      };
    }), 1000);
  }

  handleStartStopnosms() {
    this.timer = setInterval(() => this.setState(prevState => {
      if (prevState.countnosms === 0) return null;

      return {
        countnosms: prevState.countnosms - 1,
      };
    }), 1000);
  }

  eventoEnviarMensaje = async () => {
    this.postAutUrl()
    this.handleStartStop()
    this.handleStartStopnosms()
    this.setState({
      timeout: false,
      count: 90,
      secondsToWait: 0,
      timeoutnosms: false,
      countnosms: 15
    })

    // await reSendMsjSms(this)
  }

  postAutUrl = async () => {
    // console.log("this.state.flujo.creditApplicationId=>", this.state.flujo.creditApplicationId)
    let ip = sessionStorage.getItem("ip") || "127.0.0.1"
    // const info = {
    //   creditApplicationId: this.state.flujo.creditApplicationId,
    //   phoneNumber: this.state.phone,
    //   sourceIp: "127.0.0.1"
    // };
    let navigateUrl = ''
    // let phone = this.state.phone === undefined ?   '9254974638' : this.state.phone

    const res = await proveSrv.postAutUrl(this.state.flujo.creditApplicationId, this.state.phone, ip)
      // axios.post('https://afi-prove-service.afi.calidad-architect.com/v1/prove/instantLink/getAuthUrl', info)
      .then((res) => {
        console.log("response postAutUrl", res)
        if (res.data.response?.authenticationUrl) {
          this.setState({ authenticationUrl: res.data.response.authenticationUrl })
          // console.log("1er servicio correcto")
          this.checkInstantLink()
        } else {
          // navigateUrl = getPathById(this.props.pageContext.errorPath, 'link')
          // navigate(navigateUrl)
          navigate(this.props.pageContext.next.path)
        }
      })
      .catch(error => {
        console.error(error)
        // navigateUrl = getPathById(this.props.pageContext.errorPath, 'link')
        // navigate(navigateUrl)
        navigate(this.props.pageContext.next.path)
        // this.setState({ errorService: true, mensaje: this.props.errorMessage })
      })

  }

  checkInstantLink = async () => {
    if (!this.state.checkInstant) {
      let navigateUrl = ''
      const res = await proveSrv.getCheckInstantLink(this.state.flujo.creditApplicationId)
        // axios.get(`https://afi-prove-service.afi.calidad-architect.com/v1/prove/checkInstantLink/${this.state.flujo.creditApplicationId}`)
        .then((res) => {
          console.log("response", res)
          if (res.data.path === null || res.data.path === "Pending") {
            // console.log("volviendo a cargar 2do servicio", res.data.path)
            this.setState({ checkInstant: false })
            setTimeout(() => { this.checkInstantLink() }, 15000);
          } else {
            // console.log("2do servicio correcto")
            this.setState({ checkInstant: true })
            this.setState({ instantPath: res.data.path })
            if (res.data.path !== "Red") {
              if (!this.state.checkProve) {
                console.log("Siguiente servicio", this.state.checkProve)
                // this.getTrustScore()
                this.setState({ loading: false, next_step: true, swNavigate: 'next', btnReLink: false })
              } else {
                // console.log("Cerrando ventana", this.state.checkProve)
                this.setState({ next_step: true, btnReLink: false })
                // window.close();
              }

            } else {
              navigateUrl = getPathById(this.props.pageContext.errorPath, 'link')
              navigate(navigateUrl)
              // this.setState({ swNavigate: 'link', next_step: true, btnReLink: false })

            }

          }

        })
        .catch(error => {
          console.error(error)
          // navigateUrl = getPathById(this.props.pageContext.errorPath, 'link')
          navigate(this.props.pageContext.next.path)
          // this.setState({ errorService: true, mensaje: this.props.errorMessage })          
        })
    }

  }

  getTrustScore = async () => {
    this.setState({ loading: true })
    let navigateUrl = ''
    const res = await proveSrv.getTrustScore(this.state.flujo.creditApplicationId, this.state.phone)
      // axios.get(`https://afi-prove-service.afi.calidad-architect.com/v1/prove/trustScore/${this.state.flujo.creditApplicationId}/${this.state.phone}`)
      .then((res) => {
        console.log("3er servicio correcto", res.data);
        if (res.data.response?.trustScore) {
          this.setState({ trustScore: res.data.response.trustScore })
          if (this.state.instantPath !== "Red" && res.data.response.trustScore > 0) {
            // navigateUrl = this.props.pageContext.next.path
            // navigate(navigateUrl)
            this.setState({ loading: false, next_step: true, swNavigate: 'next', btnReLink: false })
          } else {
            navigateUrl = getPathById(this.props.pageContext.errorPath, 'link')
            navigate(navigateUrl)
            // this.setState({ swNavigate: 'trust', next_step: true, btnReLink: false })
          }
        }
      })
      .catch(error => {
        console.error(error)
        // navigateUrl = getPathById(this.props.pageContext.errorPath, 'link')
        navigate(this.props.pageContext.next.path)
      })


  }

  nextStep = () => {
    let navigateUrl =  this.props.pageContext.next.path
    // switch (this.state.swNavigate) {
    //   case 'link':
    //     navigateUrl = getPathById(this.props.pageContext.errorPath, 'link')
    //     break;
    //   case 'trust':
    //     navigateUrl = getPathById(this.props.pageContext.errorPath, 'trust')
    //     break;
    //   case 'next':
    //     navigateUrl = this.props.pageContext.next.path
    //     break;
    // }
    navigate(navigateUrl)
  }

  async componentDidMount() {
    const response = axios.get('https://www.cloudflare.com/cdn-cgi/trace')
    .then((res) => {
        // console.log("response ", res)
        let data = res.data.trim().split('\n').reduce(function (obj, pair) {
            pair = pair.split('=');
            return obj[pair[0]] = pair[1], obj;
        }, {});
        // console.log("data=>", data.ip);
        sessionStorage.setItem("ip", data.ip);
    })
    
    const tDatos = JSON.parse(sessionStorage.getItem('tdatos')) || {}
    let urlParams = new URLSearchParams(window.location.search)
    const phoneGet = urlParams.get('phoneNumber') || ''
    // console.log("getPhone", phoneGet)

    if (urlParams.has('phoneNumber') && urlParams.get('phoneNumber') !== tDatos.telefono) {
      console.log("vacio sessionS")
      sessionStorage.setItem('tdatos', JSON.stringify({ telefono: phoneGet }))
      this.setState({ phone: phoneGet })
      this.checkInstantLink()
    } else {
      this.setState({ phone: tDatos.telefono, checkProve: true })
      // console.log("tDatos.telefono=>", tDatos.telefono)
      setTimeout(() => { this.postAutUrl() }, 2000);

    }
    // const tDatos = JSON.parse(sessionStorage.getItem('tdatos')) || {}

    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId
    const customers = new VerificacionesTuboService(id);
    let cavsection = await customers.cavSectionPhone(id)
    console.log("cavsection=>",cavsection)
    this.setState({ phone_servicio: cavsection?.data[0].phoneNumber })

  }

  render() {
    const {
      textCount1,
      textCount2,
      titleIcon,
      textInfo,
      textFormHelper,
      textButton,
      textValidateInfo,
      textValidateInfoOk,
    } = this.props
    // const progress = this.state.next_step ? CheckIcon : CircularProgress
    const telefono = this.state.phone ? this.state.phone : this.state.phone_servicio
    let textValidate = this.state.next_step ? textValidateInfoOk : textValidateInfo
    let texto = this.state.count > 0 ? `   ${textCount1}  ${this.state.count}` : `${textCount2} ${telefono}`
    let loader = CircularProgress
    return (

      <Container justify="center" maxWidth="sm">
        <Grid container justify="center">
          <Grid item style={{ textAlign: "center" }}>
            <div css={root}>
                <MobileFriendly css={iconStyle} />
                <Typography css={h1Style} component='span'>
                    {titleIcon}
                </Typography>      
            </div>
            {/* <TitleIcon icon={MobileFriendly} title={titleIcon} textColor /> */}
          </Grid>
          {/* <Grid>{this.state.authenticationUrl}</Grid> */}
        </Grid>
        <br />

        <CardElevation>
          <Grid container spacing={3} >
            <Grid item xs={12} >
              <Typography variant="body1" component="body1">
                {textInfo}
              </Typography>
            </Grid>
            <Grid item xs={12}>

            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {this.state.next_step ? <CheckIcon color="primary" fontSize="large" /> : <CircularProgress color="primary" />}

              <p><strong>{textValidate}</strong></p>
            </Grid>

            <Grid item xs={12}>
              {this.state.btnReLink &&
                <>
                  <ButtonDegradeFirma id="firma-digital-enviar-codigo-button" disabled={!this.state.timeout} icon={this.state.count > 0 ? loader : MobileFriendly} textButton={texto} onClick={this.eventoEnviarMensaje} />
                  <br />
                  {/* {
                    this.state.timeout &&
                    <FormHelperText css={this.state.helpTxt} ><p onClick={() => { this.eventoLink() }} style={{ color: "#bf0000", cursor: "pointer" }} >{textFormHelper}</p></FormHelperText>
                  } */}
                </>
              }

            </Grid>

            <Grid container>
              <Grid item style={{ textAlign: "right" }} xs={12}>
                <br />
                <ButtonDegrade
                  id="firma-digital-continuar-button"
                  icon={MobileFriendly}
                  disabled={!this.state.next_step}
                  textButton={textButton}
                  onClick={this.nextStep}
                />
              </Grid>
            </Grid>

          </Grid>
        </CardElevation>
        <br />
       


        <Backdrop open={this.state.loading} /*css={backDropCss}*/ style={{ zIndex: '1000' }}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            {this.state.mensaje}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.success} autoHideDuration={3000} onClose={() => this.setState({ success: false })}>
          <Alert variant="filled" severity="success" onClose={() => this.setState({ success: false })}>
            {this.state.mensaje}
          </Alert>
        </Snackbar>
      </Container>
    );
  }

}

VerificacionProve.defaultProps = {
  textCount1: 'REACTIVANDO LINK EN:',
  textCount2: 'REENVIAR LINK AL:',
  titleIcon: 'Verificación de número telefónico',
  textInfo: 'Para poder continuar con el proceso de solicitud de préstamo,  necesitamos validar tu número de teléfono celular. Recibirás un mensaje de texto con un link al número telefónico que nos proporcionaste. Sólo tienes que dar clic en el enlace.',
  textFormHelper: 'No he recibido ningún SMS',
  textButton: 'CONTINUAR',
  errorMessage: 'Lo sentimos, por el momento el servicio no se encuentra disponible',
  textValidateInfo: 'Validando tu informacion por favor espere....',
  textValidateInfoOk: 'Tu información fue procesada.',
}

export default withThemeProps(VerificacionProve, 'VDNPVerificacionProve')